@import './variables.scss';

.white {
  color: white;
}

* {
  font: 100% $regular;
}

body {
  margin: 0;
  background-color: $yellow;
}

h1 {
  font: 100% $bold;
  text-align: center;
  color: $grey;
  padding: 0 2rem;
  font-size: 40px;
}
h2 {
  font: 150% $bold;
}
h3 {
  font-size: 25px;
}
img {
  object-fit: contain;
  flex-grow: 0;
}
p {
  padding: 0 2rem;
  color: $grey;
  line-height: 1.4;
  font-size: 16px;
  text-align: justify;
}
h1, h2, h3, h4, p, li, footer {
  color: $blue;
}
section {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 10vh 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: pre-line;
}
footer {
  text-align: center;
  font-size: 12px;
  padding: 2rem 0;
  border-top: dotted $red 3px;
}
a {
  color: $red;
}
a.button {
  background-color: $red;
  color: $light-yellow;
  text-decoration: none;
  padding: 1rem 2rem;
  border-radius: 5px;
  box-shadow: rgba(000, 000, 000, 0.2) 2px 2px;
  transition: 0.3s ease-in-out;
  flex: 0;
  text-align: center;
  width: min-content;
  white-space: nowrap;
  margin: 2rem auto;
  font: 100% $bold;
  text-transform: uppercase;
}

.button:hover {
  color: $red;
  background-color: $yellow;
  border: solid 1px;
}
