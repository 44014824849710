@import './variables.scss';

nav.mobile {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  position: absolute;
  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    .menuButton {
      top: 27px;
    }
    .logo {
      padding: 0;
      width: 40px;
    }
  }
  .logo {
    margin: 0 auto;
  }
  ul {

    display: none;
  }
  li {
    list-style: none;
    margin: 1rem;
    border-bottom: 1px $yellow solid;
    a {
      color: $yellow;
      text-decoration: none;
      text-transform: uppercase;
      font: 100% $bold;
    }
  }
  &.solid {
    background-color: $red;
    position: relative;
    padding-bottom: 1rem;
    &.opened {
      background-color: $red;
    }
  }
  &.opened {
    background-color: rgba(0,0,0,0.5);
    ul {
      display: block;
    }
  }
  div.menuButton {
    position: absolute;
    left: 45px;
    top: 60px;
    i {
      color: $yellow;
      font-size: 30px;
    }
  }
}
.desktop {
  display: none;
  width: 100%;
  padding: 1rem auto 1rem auto;
  ul {
    margin: 0;
    padding: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    li {
      margin: 0 2rem;
      list-style: none;
      a {
        color: $yellow;
        text-decoration: none;
        text-transform: uppercase;
        font: 100% $regular;
      }
    }
  }
  &.solid {
    background-color: $red;
    margin-bottom: 1rem;
  }
  &.transparent {
    position: absolute;
    background-color: transparent;
  }
  .logo {
    margin: 0 4rem;
  }
}
@media (min-width: 769px) {
  nav.mobile {
    display: none;
  }
  .desktop {
    display: block;
  }
  .transparent {
    position: absolute;
    background-color: transparent;
  }
}
