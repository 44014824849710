@import './variables.scss';

main {
  width: 100%;
}
.details {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .intro {
    margin: 3rem 0;
  }
  .button {
    margin-top: 3rem;
  }
  p {
    max-width: 800px;
  }
}
div.bigPictures {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 2rem 0;
}
.wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .info {
    padding: 3rem 0;
  }
}
div.categoryImage {
  width: 100%;
  background-size: cover;
  background-position: center;
  min-height: 400px;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h2 {
    text-align: center;
    background-color: $yellow;
    padding: 0.5rem 1rem;
    font: 200% $bold;
  }
  p {
    background-color: $red;
    color: $yellow;
    max-width: 300px;
    padding: 2rem;
  }
}

.categoryDescriptions {
  max-width: 1000px;
  // display: flex;
  p {
    margin: 2rem 0;
    span {
      font: 100% $bold;
      font-size: 1.3rem;
    }
    text-align: left;
  }
}
.intermezzo {
  height: 70vh;
  background: url("../img/alarm.jpg") center/cover;
}

@media (max-width: 768px) {
  .bigPictures {
    flex-direction: column;
    .categoryWrap {
      width: 100%;
      h2 {
        font: 150% $bold;
        text-align: center;
      }
    }
  }
}
