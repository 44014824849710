.title-slide {
  height: 100vh;
  background: url("../img/Bckground-fox-ubhan.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.title {
  height: 100%;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  * {
    margin: 1rem;
  }

  .date {
    width: 60%;
    margin: 0 auto;
    max-width: 200px;
  }
}
.intro {
  display: flex;
  flex-direction: column;
  div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    max-width: 1000px;
    margin: 3vh 0;
    .camera {
      max-width: 150px;
    }
    * {
      max-width: 400px;
    }
  }
}

.intermezzo1 {
  height: 70vh;
  background: url("../img/fox-berlin-1.jpg") center/cover;
}

.intermezzo2 {
  height: 70vh;
  background: url("../img/fox-berlin-2.jpg") center/cover;
}

.intermezzo3 {
  height: 70vh;
  background: url("../img/kurfuestendamm.jpg") center/cover;

}

.wolfskino {
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.varco {
  max-width: 800px;
  margin: 5vh;
  display: flex;
  flex-direction: column;
}

.logoVarco {
  width: 80vw;
  margin: 2rem auto;
  max-width: 350px;
}

section.contacts {
  padding: 0;
  margin-bottom: 5rem;
}
article.person {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
  flex: 1 1 0;
  .title {
    text-align: center;
  }
}
section.mission {
  max-width: 1200px;
  p {
    ul {
      li {
        font-size: 1.5rem;
      }
    }
  }
}
.avatar {
  width: 250px;
}
section.people {
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 100%;
  max-width: 1000px;
}

@media (max-width: 930px) {
  section.people {
  flex-direction: column;
  }
}
