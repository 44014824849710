@import './variables.scss';

.categoryList {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  .categoryCard {
    width: 250px;
    height: 250px;
    margin: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    h3 {
      background-color: $yellow;
      padding: 0 1rem;
      margin-bottom: 5rem;
      text-align: center;
      font: 150% $bold;
      margin: 1rem 1rem;
    }
  }
  article {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      span {
        font: 100% $bold;
        font-size: 1.3rem;
      }
      text-align: left;
    }
  }
}

div.categoryDetails {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
